import React, { useEffect, useState } from "react";
import Popup from '../../../../../presentationals/Popup/Popup.component';
import { getAllExpenseCategory, getAllExpenseSubCategory, saveExpenseTarget } from "../../targetactioncreator";
import Loader from '../../../../../presentationals/Loader/Loader.component';
import moment from "moment";
const defaultDate = (type) => {
    const d = new Date();
    const year = d.getFullYear();
    const day = String(d.getDate()).padStart(2, '0');
    let month;
    if (type == 'currentMonth') {
        month = String(d.getMonth() + 1).padStart(2, '0');
    } else if (type == 'nextMonth') {
        month = String(d.getMonth() + 2).padStart(2, '0');
    }

    return `${year}-${month}-${day}`;
}

const EditTarget = ({ editData }) => {
    const [loader, setLoader] = useState(false);
    const [categoryData, setCategoryData] = useState({
        "categoryId": "",
        "categoryName": "",
        "subCategoryId": "",
        "subCategoryName": "",
        "targetAmount": 0.0,
        "targetPercentageASPerAUM": 0.0,
        "currentAmount": 0.0,
        "currentPercentageASPerAUM": 0.0,
        "deviationAmount": 0.0,
        "deviationPercentageASPerAUM": 0.0,
        "comment": "",
        "active": true,
        "startDate": defaultDate('currentMonth'),
        "endDate": defaultDate('nextMonth'),
        "aum": 0.0
    });

    // const categoryOptions = [{
    //     "categoryId": "SWXaW31",
    //     "categoryName": "Utilities",
    //     "subCategoryId": "456",
    //     "subCategoryName": "Electricity",
    // }];
    const [categoryList, setCategoryList] = useState('')
    const [subcategoryList, setsubCategoryList] = useState('')

    const [sidePopup, setPopup] = useState(false);
    const [popupStatus, setPopupStatus] = useState('');
    const [errors, setErrors] = useState({});

    const closePopup = () => {
        setPopup(false);
    };

    // const handleChange = (e) => {
    //     const { name, value } = e.target;
    //     setCategoryData((prevData) => ({
    //         ...prevData,
    //         [name]: value,
    //     }));
    // };
    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name === "categoryId" || name === "subCategoryId") {
            if (name === "categoryId") {
                const selectedCategory = categoryList.find(
                    (category) => category.categoryId === value
                );
                setCategoryData((prevData) => ({
                    ...prevData,
                    categoryId: value,
                    categoryName: selectedCategory ? selectedCategory.categoryName : "",
                }));
            }
            if (name === "subCategoryId") {
                const selectedCategory = subcategoryList.find(
                    (category) => category.subCategoryId === value
                );
                setCategoryData((prevData) => ({
                    ...prevData,
                    subCategoryId: value,
                    subCategoryName: selectedCategory ? selectedCategory.subCategoryName : "",
                }));
            }
        } else {
            setCategoryData((prevData) => ({
                ...prevData,
                [name]: value,
            }));
        }
    };


    const validateForm = () => {
        let formErrors = {};
        let isValid = true;

        if (!categoryData.subCategoryId) {
            formErrors.subCategoryId = "Subcategory is required";
            isValid = false;
        }
        if (!categoryData.aum) {
            formErrors.aum = "AUM is required";
            isValid = false;
        }
        if (!categoryData.targetAmount) {
            formErrors.targetAmount = "Target Amount is required";
            isValid = false;
        }
        if (!categoryData.targetPercentageASPerAUM) {
            formErrors.targetPercentageASPerAUM = "Target % AUM is required";
            isValid = false;
        }
        if (!categoryData.startDate) {
            formErrors.startDate = "Date is required";
            isValid = false;
        }
        if (!categoryData.endDate) {
            formErrors.endDate = "Date is required";
            isValid = false;
        }

        setErrors(formErrors);
        return isValid;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            setLoader(true)
            const fromDate = moment(categoryData.startDate).format('DD-MM-YYYY');
            const toDate = moment(categoryData.endDate).format('DD-MM-YYYY');
            const formattedData = { ...categoryData, startDate: fromDate, endDate: toDate };
            console.log("Category Data Saved", formattedData);
            saveExpenseTarget(formattedData, callback => {
                setLoader(false)
                if (callback.message === 'success') {
                    setPopup(true);
                    setPopupStatus('Data Saved Successfully!');
                } else {
                    setPopup(true);
                    setPopupStatus('Please try again later!');
                }
                removePopup();
            });
        }
    };

    const removePopup = () => {
        setTimeout(() => {
            closePopup();
        }, 5000);
    };

    useEffect(() => {
        if (editData.id) {
            setCategoryData({
                categoryId: editData.categoryId || "",
                categoryName: editData.categoryName || "",
                subCategoryId: editData.subCategoryId || "",
                subCategoryName: editData.subCategoryName || "",
                targetAmount: editData.targetAmount || 0.0,
                targetPercentageASPerAUM: editData.targetPercentageASPerAUM || 0.0,
                currentAmount: editData.currentAmount || 0.0,
                currentPercentageASPerAUM: editData.currentPercentageASPerAUM || 0.0,
                deviationAmount: editData.deviationAmount || 0.0,
                deviationPercentageASPerAUM: editData.deviationPercentageASPerAUM || 0.0,
                comment: editData.comment || "",
                aum: editData.aum || 0.0,
                active: editData.active || true,
                id: editData.id,
                startDate: editData.startDate ? moment(editData.startDate, 'DD-MM-YYYY').format('YYYY-MM-DD') : '',
                endDate: editData.endDate ? moment(editData.endDate, 'DD-MM-YYYY').format('YYYY-MM-DD') : '',
            });
        } else {
            setCategoryData({
                categoryId: "",
                categoryName: "",
                subCategoryId: "",
                subCategoryName: "",
                targetAmount: 0.0,
                targetPercentageASPerAUM: 0.0,
                currentAmount: 0.0,
                currentPercentageASPerAUM: 0.0,
                deviationAmount: 0.0,
                aum: 0.0,
                deviationPercentageASPerAUM: 0.0,
                comment: "",
                active: true,
                startDate: defaultDate('currentMonth'),
                endDate: defaultDate('nextMonth'),
            })
        }
    }, [editData]);
    useEffect(() => {
        getAllExpenseCategory("notRequired", '', '', callback => {
            if (callback && callback?.status == 200) {
                setCategoryList(callback.data)
            } else {
                setPopup(true);
                setPopupStatus('Please try again later!');
                removePopup();
            }
        })
        getAllExpenseSubCategory('notRequired', '', '', callback => {
            if (callback && callback?.status == 200) {
                setsubCategoryList(callback.data)
            } else {
                setPopup(true);
                setPopupStatus('Please try again later!');
                removePopup();
            }
        })
    }, [])

    return (
        <div className="container">
            {sidePopup && <Popup popupStatus={popupStatus} closePopup={closePopup} />}
            {loader ? <Loader /> : ''}
            <div className="target-box">
                <h3>Add/Update Target Information</h3>
                <hr style={{ borderTop: '1px solid #000' }} />
                <form onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="col-sm-6 col-xs-12">
                            <div style={{ display: 'flex', marginTop: '20px' }}>
                                <div style={{ width: '50%' }}>
                                    <label style={{ marginTop: '10px' }}>Category</label>
                                </div>
                                <div style={{ width: '50%' }}>
                                    <select
                                        style={{ background: '#fff', border: '1px solid' }}
                                        className="form-control"
                                        name="categoryId"
                                        value={categoryData.categoryId}
                                        onChange={handleChange}
                                    >
                                        <option value="">Select Category</option>
                                        {categoryList ? categoryList.map((category) => (
                                            <option key={category.categoryId} value={category.categoryId}>
                                                {category.categoryName}
                                            </option>
                                        )) : ""}
                                    </select>
                                    {errors.categoryId && (
                                        <span style={{ color: 'red', fontSize: '10px', marginTop: '5px' }}>{errors.categoryId}</span>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-xs-12">
                            <div style={{ display: 'flex', marginTop: '20px' }}>
                                <div style={{ width: '50%' }}>
                                    <label style={{ marginTop: '10px' }}>Subcategory</label>
                                </div>
                                <div style={{ width: '50%' }}>
                                    <select
                                        style={{ background: '#fff', border: '1px solid' }}
                                        className="form-control"
                                        name="subCategoryId"
                                        value={categoryData.subCategoryId}
                                        onChange={handleChange}
                                    >
                                        <option value="">Select Subcategory</option>
                                        {subcategoryList ? subcategoryList.map((category) => (
                                            <option key={category.subCategoryId} value={category.subCategoryId}>
                                                {category.subCategoryName}
                                            </option>
                                        )) : ""}
                                    </select>
                                    {errors.subCategoryId && (
                                        <span style={{ color: 'red', fontSize: '10px', marginTop: '5px' }}>{errors.subCategoryId}</span>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-6 col-xs-12">
                            <div style={{ display: 'flex', marginTop: '20px' }}>
                                <div style={{ width: '50%' }}>
                                    <label style={{ marginTop: '10px' }}>Target Amount</label>
                                </div>
                                <div style={{ width: '50%' }}>
                                    <input
                                        style={{ background: '#fff', border: '1px solid' }}
                                        className="form-control"
                                        type="number"
                                        name="targetAmount"
                                        value={categoryData.targetAmount}
                                        onChange={handleChange}
                                    />
                                    {errors.targetAmount && (
                                        <span style={{ color: 'red', fontSize: '10px', marginTop: '5px' }}>{errors.targetAmount}</span>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-xs-12">
                            <div style={{ display: 'flex', marginTop: '20px' }}>
                                <div style={{ width: '50%' }}>
                                    <label style={{ marginTop: '10px' }}>Target % AUM</label>
                                </div>
                                <div style={{ width: '50%' }}>
                                    <input
                                        style={{ background: '#fff', border: '1px solid' }}
                                        className="form-control"
                                        type="number"
                                        name="targetPercentageASPerAUM"
                                        value={categoryData.targetPercentageASPerAUM}
                                        onChange={handleChange}
                                    />
                                    {errors.targetPercentageASPerAUM && (
                                        <span style={{ color: 'red', fontSize: '10px', marginTop: '5px' }}>{errors.targetPercentageASPerAUM}</span>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-6 col-xs-12">
                            <div style={{ display: 'flex', marginTop: '20px' }}>
                                <div style={{ width: '50%' }}>
                                    <label style={{ marginTop: '10px' }}>From Date</label>
                                </div>
                                <div style={{ width: '50%' }}>
                                    <input
                                        style={{ background: '#fff', border: '1px solid' }}
                                        className="form-control"
                                        type="date"
                                        name="startDate"
                                        value={categoryData.startDate}
                                        onChange={handleChange}
                                    />
                                    {errors.startDate && (
                                        <span style={{ color: 'red', fontSize: '10px', marginTop: '5px' }}>{errors.startDate}</span>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-xs-12">
                            <div style={{ display: 'flex', marginTop: '20px' }}>
                                <div style={{ width: '50%' }}>
                                    <label style={{ marginTop: '10px' }}>To Date</label>
                                </div>
                                <div style={{ width: '50%' }}>
                                    <input
                                        style={{ background: '#fff', border: '1px solid' }}
                                        className="form-control"
                                        type="date"
                                        name="endDate"
                                        min={categoryData.startDate}
                                        value={categoryData.endDate}
                                        onChange={handleChange}
                                    />
                                    {errors.endDate && (
                                        <span style={{ color: 'red', fontSize: '10px', marginTop: '5px' }}>{errors.endDate}</span>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-xs-12">
                            <div style={{ display: 'flex', marginTop: '20px' }}>
                                <div style={{ width: '50%' }}>
                                    <label style={{ marginTop: '10px' }}>AUM</label>
                                </div>
                                <div style={{ width: '50%' }}>
                                    <input
                                        style={{ background: '#fff', border: '1px solid' }}
                                        className="form-control"
                                        type="number"
                                        name="aum"
                                        min={categoryData.aum}
                                        value={categoryData.aum}
                                        onChange={handleChange}
                                    />
                                    {errors.aum && (
                                        <span style={{ color: 'red', fontSize: '10px', marginTop: '5px' }}>{errors.aum}</span>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-xs-12">
                            <div style={{ display: 'flex', marginTop: '20px' }}>

                                <div style={{ width: '50%' }}>
                                    <label style={{ marginTop: '10px' }}>Active</label>
                                    &nbsp;&nbsp;
                                    <input
                                        style={{ marginTop: '5px' }}
                                        type="checkbox"
                                        name="active"
                                        checked={categoryData.active}
                                        onChange={(e) => setCategoryData((prevData) => ({
                                            ...prevData,
                                            active: e.target.checked,
                                        }))}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">

                    </div>

                    <div className="row">

                    </div>

                    <div className="row">
                        <div className="col-sm-12 col-xs-12">
                            <div style={{ display: '', marginTop: '20px' }}>
                                <div style={{ width: '' }}>
                                    <label style={{ marginTop: '10px' }}>Comment</label>
                                </div>
                                <div style={{ width: '' }}>
                                    <textarea
                                        style={{ background: '#fff', border: '1px solid' }}
                                        className="form-control"
                                        name="comment"
                                        value={categoryData.comment}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">

                    </div>

                    <div className="row">
                        <div className="col-sm-12 col-xs-12" style={{ marginTop: '20px', textAlign: 'center' }}>
                            <button type="submit" className="btn btn-primary">Save Targets</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default EditTarget;
