import React, { useState, useEffect } from "react";
import { getAutomatedScoringByUserId, getCategoryPointers, saveScoringApi } from "../scoreConfig/actionCreatorScore";
import Skeleton from "react-loading-skeleton";
import { getallDBRdataApi } from "../AdminActionCreator.component";
import Loader from '../../../presentationals/Loader/spinner'
import NodataImage from '../../../../images/nodata.png'

const CreditPopup = ({ allDetail, close, admin }) => {
    const [userIdData, setUserIdData] = useState([]);
    const [loaderState, setLoaderState] = useState(false);
    const [internalScoring, setInternalData] = useState({});
    const [segreegatedData, setSegreegatedData] = useState([]);
    const [finalData, setFinalData] = useState('');

    useEffect(() => {
        getData();
        getAlldata();
    }, []);

    const getData = () => {
        setLoaderState(true);
        getAutomatedScoringByUserId(allDetail.userId, allDetail.loanId, 0, callback => {
            setUserIdData(callback.credit_scoring);
            setLoaderState(false);
        });
    };

    const getAlldata = () => {
        let changeData = { ...internalScoring };

        getallDBRdataApi(allDetail.userId, callback => {
            if (callback !== '' && callback !== null && callback.decision !== '' && callback.decision !== undefined) {
                getallDBRdataApi(allDetail.userId, callback => {
                    if (callback !== '' && callback !== null && callback.decision !== '' && callback.decision !== undefined) {
                        changeData.stability = callback.stability
                        changeData.residence = callback.residence
                        changeData.debts = callback.debts
                        changeData.multiplePayday = callback.multiplePayday
                        changeData.overDueMsgsTrend = callback.overDueMsgsTrend
                        changeData.bureau = callback.bureau
                        changeData.corporate = callback.corporate
                        changeData.access = callback.access
                        changeData.ntc = callback.ntc
                        changeData.kyc = callback.kyc
                        changeData.salary = callback.salary
                        changeData.obligations = callback.obligations
                        changeData.decision = callback.decision
                        changeData.dbrpercentage = callback.dbrpercentage
                        changeData.scoring = callback.scoring
                        changeData.emi = callback.emi

                    }
                    setInternalData(changeData)

                })


            }
            setInternalData(changeData);
        });
    };

    const saveDataFunction = () => {
        let data = {
            "dbrCalculationsPO": {
                "stability": internalScoring.stability,
                "residence": internalScoring.residence,
                "debts": internalScoring.debts,
                "multiplePayday": internalScoring.multiplePayday,
                "overDueMsgsTrend": internalScoring.overDueMsgsTrend,
                "bureau": internalScoring.bureau,
                "corporate": internalScoring.corporate,
                "access": internalScoring.access,
                "ntc": internalScoring.ntc,
                "kyc": internalScoring.kyc,
                "userId": allDetail.userId,
                "loanId":allDetail.loanId
            },
            "creditUserScoring": segreegatedData


        }
        if (data.dbrCalculationsPO && data.creditUserScoring) {
            setLoaderState(true)

            saveScoringApi(data, callback => {
                setFinalData(callback);
                setLoaderState(false);
            });
        }
    };

    const changescoreIncludedFlag = (policy, newValue, type) => {
        const updatedUserData = userIdData.map(data => {
            if (data === policy) {
                if (type === 'flag') data.scoreIncludedFlag = newValue;
                else if (type === 'comment') data.comment = newValue;
                else if (type === 'creditorScoring') data.creditorScoring = newValue;
            }
            return data;
        });

        setUserIdData(updatedUserData);

        let dATAtoSend = []
        updatedUserData.map((data, i) => {
            dATAtoSend.push({
                "userId": allDetail.userId,
                "loanId": allDetail.loanId,
                "categoryId": data.categoryId,
                "categoryName": data.categoryName,
                "scoreType": data.scoreType,
                "score": data.score,
                "scoreIncludedFlag": data.scoreIncludedFlag,
                "comment": data.comment,
                "creditorScoring": data.creditorScoring,
                "editedBy": admin.emailId,
            

            })

        })
        setSegreegatedData(dATAtoSend)

    };



    // const uniqueCategories = Array.from(new Set(userIdData.map(policy => policy.categoryName)));
    let uniqueCategories = []
    if (userIdData) {
        uniqueCategories = Array.from(new Set(userIdData.map(policy => policy.categoryName)));
        // Continue with your code using uniqueCategories
    } else {
        // Handle the case where userIdData is null
        console.error('userIdData is null');
    }
    
    return (
        <>
            {loaderState ?
                <div className="text-center" style={{marginTop:'5%'}}> 
                    <Loader />
                </div>

                :
uniqueCategories.length>0?
                <div className=' container' style={{ marginBottom: '20px' }}>

                    <div className="excel-table" style={{ marginLeft: 'auto', marginRight: 'auto', textAlign: 'center', overflow: 'auto' }}>
                        <table>
                            <thead>
                                <tr>
                                    <th className="text-center">Group</th>
                                    <th className="text-center">Particulars</th>
                                    <th className="text-center">Report</th>
                                    <th className="text-center">Score</th>
                                    <th className="text-center">Comment</th>
                                    <th className="text-center">Creditor Score</th>
                                </tr>
                            </thead>


                            {loaderState === false && uniqueCategories.map((category, index) => {
                                const categoryPolicies = userIdData.filter(policy => policy.categoryName === category);
                                return (
                                    <tr key={index} style={{}}>

                                        <td className="text-left" style={{ width: '400px' }}>
                                            {categoryPolicies.map((policy, idx) => (
                                                <React.Fragment key={idx}>
                                                    {policy.scoreType}
                                                    {idx !== categoryPolicies.length - 1 && <><hr style={{ marginTop: '5px', marginBottom: '18px', borderTop: '1px dotted black' }} /></>}
                                                </React.Fragment>
                                            ))}
                                        </td>
                                        <td className="text-center" style={{ width: '100px' }}><h4><b>{category}</b></h4></td>
                                        <td className="text-center" style={{ width: '60px' }}>

                                            {categoryPolicies.map((policy, idx) => (
                                                <>
                                                    <input
                                                        type="checkbox"
                                                        onChange={(e) => changescoreIncludedFlag(policy, e.target.checked, 'flag')}
                                                        checked={policy.scoreIncludedFlag}
                                                        key={idx}
                                                        style={{ marginBottom: '18px' }}
                                                    />
                                                    <br />
                                                </>
                                            ))}

                                        </td>
                                        <td className="text-center" style={{ width: '25px' }}>
                                            {categoryPolicies.map((policy, idx) => (
                                                <React.Fragment key={idx}>
                                                    {policy.score}
                                                    {idx !== categoryPolicies.length - 1 && <><br /><br /></>}
                                                </React.Fragment>
                                            ))}
                                        </td>
                                        <td className="text-left" style={{ width: '200px' }}>
                                            {categoryPolicies.map((policy, idx) => (
                                                <input
                                                    className="form-control"
                                                    style={{ marginBottom: '1px', padding: '8px', background: '#fff', border: '1px solid black' }}
                                                    type="text"
                                                    value={policy.comment}
                                                    onChange={(e) => changescoreIncludedFlag(policy, e.target.value, 'comment')}
                                                    key={idx}
                                                    placeholder="enter comment"
                                                />
                                            ))}
                                        </td>
                                        <td className="text-left" style={{ width: '25px' }}>
                                            {categoryPolicies.map((policy, idx) => (
                                                <input
                                                    className="form-control"
                                                    style={{ marginBottom: '1px', padding: '8px', background: '#fff', border: '1px solid black' }}
                                                    type="number"
                                                    value={policy.creditorScoring}
                                                    onChange={(e) => changescoreIncludedFlag(policy, e.target.value, 'creditorScoring')}
                                                    key={idx}
                                                    placeholder="score"

                                                />
                                            ))}
                                        </td>

                                    </tr>
                                );
                            })}
                        </table>
                        {loaderState === false ?
                            <div className="" style={{ marginTop: '20px' }}>
                                <div className="col-sm-12 col-xs-12">
                                    {finalData && finalData.finalScore ? <div className="text-center "><h3 style={{ color: '#267DFF' }}>FINAL SCORE: &nbsp;<b>{finalData.finalScore}</b></h3></div> : ""}
                                    <br />
                                    <button className="btn btn-default" style={{ padding: '10px 40px', background: '#267DFF', marginBottom: '10px', color: '#fff' }} onClick={() => saveDataFunction()}>SAVE</button>
                                </div>
                            </div>
                            : ""}
                    </div>
                </div>
                                :<div className="text-center"><img style={{width:'20%'}} src={NodataImage}/></div>

            }
        </>
    );
};

export default CreditPopup;
