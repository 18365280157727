import React, { useState } from "react";
import BackLogo from '../../../../images/rewampimages/Chevron.png'
import SettingsIcon from '@mui/icons-material/Settings';
import WalletIcon from '@mui/icons-material/Wallet';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';
import Account from "./ARMainTab";
import { UnarchiveRounded } from "@mui/icons-material";
// import TargetList from "./Targetssalary/mainTargets";

const MainEmp = ({ admin, allStatus, newDataBackground, pageViewHandler }) => {
    const [viewType, setviewType] = useState('account')
    const [editData, seteditData] = useState('')

    const editHandler = (data) => {
        console.log(data)
        if (data.id) {
            seteditData(data)
            setviewType('add')
        }



    }

    return (
        <>
            <div className="container-fluid details-new fixed-top"  >
                <div className="row details-new-tab-div" style={{ display: 'flex', justifyContent: 'end' }}>
                    <div className="col-sm-6 col-xs-12 " style={{ marginTop: '15px' }}>
                        <img onClick={(e) => pageViewHandler(e, 'landingPage')} style={{ cursor: 'pointer' }} src={BackLogo} width={'30px'} /> <button style={{ color: '#1D3557', fontWeight: '700' }} onClick={(e) => pageViewHandler(e, 'landingPage')} >Back</button>
                    </div>

                    <div className=" col-lg-6 col-xs-12" style={{ fontSize: '10px', display: 'flex', justifyContent: 'end' }}>


                        <div className="btn-group-new">

                            <button className={(viewType === 'account') ? "active" : ""} onClick={() => setviewType('account')}><WalletIcon style={{ fontSize: '27px' }} /><br />Account Reconciliation</button>
                            <button className={(viewType === 'payment') ? "active" : ""} onClick={() => setviewType('payment')}><UnarchiveRounded style={{ fontSize: '27px' }} /><br /> Unidentified Payments</button>
                        </div>

                    </div>
                </div>
            </div>
            <div style={{ marginTop: '60px' }}>
                {viewType === 'account' ?
                    <Account admin={admin}
                        allStatus={allStatus}
                        newDataBackground={newDataBackground} showButton={false} editHandler={editHandler} />
                    : ""}
                {/* {viewType === 'payment' ?
                    <Payment showButton={false} admin={admin} editData={editData} />
                    : ""} */}

            </div>

        </>
    )
}
export default MainEmp