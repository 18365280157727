import React from 'react'

const DeactivateNachPopup = ({backhandlerNach, backhandler,nachHandler, cancelLoanConfirm, confirmationText, nameOfUser, nachSourceData, selectSourceForDeactivate, nachConfirmText }) => (
    <div className="background-blur income">
        <div className="popup-main small-popup container-fluid max-width-500px dbl-border">
            <div className="row from-group">
                <div className="col-xs-12">
                    {nachConfirmText === '' ?
                        <table className='income-table'>
                            <thead>
                                <tr>
                                    <th>Name of user:</th>
                                    <td>{nameOfUser.userName}</td>
                                </tr>
                                <tr>
                                    <th>Application Id:</th>
                                    <td>{nameOfUser.applicationId}</td>
                                </tr>
                                <tr>
                                    <th>Mobile Number:</th>
                                    <td>{nameOfUser.mobileNumber}</td>
                                </tr>
                                {Object.entries(nachSourceData).map((data) => {
                                    console.log(data)
                                    return (
                                        <tr>
                                            <th>{data[1]}</th>
                                            <td><button className='btn btn-primary' onClick={() => selectSourceForDeactivate(data[0],data[1])}>Deactivate</button></td>
                                        </tr>
                                    )
                                })}
                            </thead>
                        </table>
                        : <button className='btn btn-primary' onClick={()=>backhandlerNach()}>Back</button>}
                    <h4 className="text-center">{nachConfirmText}</h4>
                </div>
            </div>

            <div className="row from-group text-center">
                <div className="col-xs-6">
                    {nachConfirmText !== '' ? <button className="profile-save-btn display-inline" onClick={(e) => nachHandler('yes')}>YES</button> : ""}
                </div>
                <div className="col-xs-6">
                    <button className="profile-save-btn display-inline" onClick={(e) => cancelLoanConfirm('no')}>Close</button>
                </div>
            </div>

        </div>
    </div>
)

export default DeactivateNachPopup;