import React, { useState, useEffect } from "react";
import './credit.scss';
import CreditDashboard from "./dashbaordboxchanges";
import PolicyDetails from "../Analysis/policyDetailsNew";
import WorkingHistory from "../Analysis/workingHistory";
import { APIS } from "../../../../utils/api-factory";
import { ROLE } from "../../../../utils/Constant";
import Repeat from "./Analysis/repeatAutomation";
import CategorHistory from "../Analysis/savedCategoryuser";
import { getSourceOfStatementParse } from "../AdminActionCreator.component";

const MainTab = ({ allDetail, admin }) => {
    const [viewPage, setViewPage] = useState('dashboard');
    const [internalViewState, setInternalViewState] = useState(false);
    const [repeatUserData, setRepeatUserData] = useState(false);
    const [statementSource, setStatementSource] = useState('');
    const [repeatEmpId, setRepeatEmpId] = useState([]);
    const [empIds, setEmpIds] = useState([]);

    const pageHandler = (data) => {
        setViewPage(data);
    };
    const changeViewState = () => {
        console.log('mjh')
        setInternalViewState(!internalViewState)
    }

    const checkRepeatUser = () => {
        fetch(`${APIS.REPEAT_USER_CHECK}${allDetail.userId}`)
            .then(res => res.json())
            .then(json => {
                setRepeatUserData(json.repeatUser);
            });
    };

    const getSource = () => {
        getSourceOfStatementParse(allDetail.userId, callback => {
            setStatementSource(callback);
        });
    };

    const getEmployeesRepeat = () => {
        fetch(`${APIS.GET_ASSIGN_BY_STATUS}?status=repeat`)
            .then(res => res.json())
            .then(res => {
                setRepeatEmpId(res);
                const uniqueEmailIds = [...new Set(res.map(item => item.emailId))];
                setEmpIds(uniqueEmailIds);
            });
    };

    useEffect(() => {
        checkRepeatUser();
        getSource();
        getEmployeesRepeat();
    }, []);

    return (
        <div className="container-fluid credit-module full-height">
            <div className="row fixed-top" style={{ marginTop: '80px', zIndex: '1', background: '#fff', borderTop: '0.5px solid grey' }}>
                <div className="btn-group btn-grp-credit" role="group" aria-label="onboard-btn" style={{ fontSize: '14px', maxWidth: 'max-content' }}>
                    <button className="btn btn-outline-primary" style={{ width: '200px', background: viewPage === 'dashboard' ? '#DBE9FF' : '#fff', color: viewPage === 'dashboard' ? '#267DFF' : '#00000073', borderBottom: viewPage === 'dashboard' ? '2px solid #267DFF' : '2px solid #DBE9FF' }} onClick={() => pageHandler('dashboard')}> Dashboard</button>
                    {admin.rolelist.indexOf(ROLE.CREDIT_ANALYSIS_WORKING_HISTORY) >= 0 ?<button className="btn btn-outline-primary" style={{ width: '200px', background: viewPage === 'history' ? '#DBE9FF' : '#fff', color: viewPage === 'history' ? '#267DFF' : '#00000073', borderBottom: viewPage === 'history' ? '2px solid #267DFF' : '2px solid #DBE9FF' }} onClick={() => pageHandler('history')}> Working History</button>:""}
                    {admin.rolelist.indexOf(ROLE.CREDIT_ANALYSIS_SCORE_ANALYSIS) >= 0 ?<button className="btn btn-outline-primary" style={{ width: '200px', background: viewPage === 'scorehistory' ? '#DBE9FF' : '#fff', color: viewPage === 'scorehistory' ? '#267DFF' : '#00000073', borderBottom: viewPage === 'scorehistory' ? '2px solid #267DFF' : '2px solid #DBE9FF' }} onClick={() => pageHandler('scorehistory')}> Score Analysis</button>:""}
                    {admin.rolelist.indexOf(ROLE.CREDIT_ANALYSIS_POLICY_CHECK) >= 0 ? <button className="btn btn-outline-primary" style={{ width: '200px', background: viewPage === 'policy' ? '#DBE9FF' : '#fff', color: viewPage === 'policy' ? '#267DFF' : '#00000073', borderBottom: viewPage === 'policy' ? '2px solid #267DFF' : '2px solid #DBE9FF' }} onClick={() => pageHandler('policy')}> Policy Check</button> : ""}
                    {repeatUserData && admin?.rolelist.includes(ROLE.CRIF_DATA) && empIds.includes(admin.emailId) && (
                        <button className="btn btn-outline-primary" style={{ width: '200px', background: viewPage === 'repeatAutomation' ? '#DBE9FF' : '#fff', color: viewPage === 'repeatAutomation' ? '#267DFF' : '#00000073', borderBottom: viewPage === 'repeatAutomation' ? '2px solid #267DFF' : '2px solid #DBE9FF' }} onClick={() => pageHandler('repeatAutomation')}> Repeat Automation Decision</button>
                    )}
                    {repeatUserData && (admin.emailId === 'piyush.jain@phocket.in' || admin.emailId === 'SuperUser') && (
                        <button className="btn btn-outline-primary" style={{ width: '200px', background: viewPage === 'repeatAutomation' ? '#DBE9FF' : '#fff', color: viewPage === 'repeatAutomation' ? '#267DFF' : '#00000073', borderBottom: viewPage === 'repeatAutomation' ? '2px solid #267DFF' : '2px solid #DBE9FF' }} onClick={() => pageHandler('repeatAutomation')}> Repeat Automation Decision</button>
                    )}
                </div>
            </div>
            <div style={{ marginTop: '120px' }}>
                {viewPage === 'dashboard' && (
                    <CreditDashboard statementSource={statementSource} changeViewState={changeViewState} internalViewState={internalViewState} admin={admin} allDetail={allDetail} />
                )}
            </div>
            <div style={{ marginTop: '170px' }}>
                {viewPage === 'policy' && <PolicyDetails allDetail={allDetail} admin={admin} />}
            </div>
            <div style={{ marginTop: '120px' }}>
                {viewPage === 'history' && <WorkingHistory allDetail={allDetail} />}
            </div>
            <div>
                {viewPage === 'scorehistory' && <CategorHistory admin={admin} allDetail={allDetail} />}
            </div>
            <div style={{ marginTop: '120px' }}>
                {viewPage === 'repeatAutomation' && <Repeat allDetail={allDetail} admin={admin} />}
            </div>
        </div>
    );
};

export default MainTab;
