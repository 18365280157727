import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ProfileContainer from './CRMMain.container';
import SettingsOption from './CRMSideNav.container';
import localStorageUtils from '../../../utils/localStorageUtil';
import CRMHeader from '../../presentationals/Admin/newAdminHeader';
//rewamp
import { APIS } from '../../../utils/api-factory';
import {
    getAllNotification, getActivityData, addLoanApprovalLead, getLoanApprovalLead,
    getAllStatus, onChangeSideNav, onContentChange, resetSettingState
} from './AdminActionCreator.component';
import NotificationPage from '../../presentationals/Admin/Notification.component'
import SoundSetting from '../../presentationals/Admin/Settings/SoundSwitch.component'
import ActivityUi from './MailActivity/Activity.container'
import { ROLE } from '../../../utils/Constant'

var interval;
var activityInterval
let maxLogoutTime = 1800

class CRMMainPageContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menuState: false,
            activityViewState: false,
            notificationState: false,
            notificationData: [],
            notificationCount: '',
            notificationDocUpCount: '',
            activityLoaderState: true,
            newDataBackground: {
                color: '',
                id: '',
            },
            notificationSoundState: false,
            pageViewState: "",
            soundSettingPageState: false,
            playingState: true,
            notificationType: '',
            lastActiveTime: new Date(),
            sessionRemainingTime: maxLogoutTime,
            menustatesmall: false,
            menuType: 'LOS'
            // generateReportState:false
        }

    }

    componentWillMount() {
        const { adminData } = this.props

        let adminRole = localStorageUtils.getFromLocalStorage('AdminRole')
        let adminRoleList = localStorageUtils.getFromLocalStorage('AdminRoleList')
        if (adminRole == 'manager' && adminRoleList.indexOf(ROLE.LEAD_MANAGEMENT) >= 0) {
            this.setState({
                pageViewState: "leadManagement",
            })
        } else {
            if (adminRoleList.indexOf(ROLE.LOAN_APPROVAL_LEADS) >= 0) {

                this.setState({
                    pageViewState: "landingPage",
                })
            }
            if (localStorage.getItem('adminName') != undefined) {
                if ((localStorage.getItem('adminName')).includes('Tea')) {

                    this.setState({
                        pageViewState: "teaGardenLeads",
                    })
                }
            }
        }


        if (localStorage.getItem('AdminemailId') === 'mala.sharma@badabusiness.com') {
            this.setState({
                pageViewState: "BadaBuisnessLeads",
            })
        }

        this.props.resetSettingState();
        this.props.getAllStatus((callBack) => {
            this.props.getAllNotification((callBack) => {
                if (callBack.count > 0 || callBack.whatsAppCount > 0) {
                    this.setState({
                        notificationSoundState: true,
                        notificationCount: callBack.count,
                        notificationDocUpCount: callBack.whatsAppCount
                    })
                }
            })

        });
    }

    componentDidMount() {
        const { admin } = this.props
        if (localStorage.getItem('AdminemailId') === 'collection1@phocket.in' || localStorage.getItem('AdminemailId') === 'null' || localStorage.getItem('AdminemailId') === '' || localStorage.getItem('AdminemailId') === 'undefined' ||
            localStorage.getItem('AdminemailId') === undefined || localStorage.getItem('AdminemailId') === null) {
            localStorage.removeItem('AdminemailId');
            localStorage.clear();

        }
        interval = setInterval(function () {
            this.props.getAllNotification((callBack) => {
                if (callBack.count > 0 || callBack.whatsAppCount > 0) {
                    this.setState({
                        notificationSoundState: true,
                        notificationCount: callBack.count,
                        notificationDocUpCount: callBack.whatsAppCount
                    })
                }
            })
        }.bind(this), 540000) // 9 min
        this.getActivityTime()


    }

    getActivityTime() {
        let adminRole = localStorageUtils.getFromLocalStorage('AdminRole')
        this.setState({
            lastActiveTime: new Date()
        })
        window.onclick = () => {
            this.setState({
                lastActiveTime: new Date()
            })
        };
        // if (adminRole != 'superuser') {
        //     activityInterval = window.setInterval(() => {
        //         let dateNowTime = new Date().getTime();
        //         let lastActiveTime = new Date(this.state.lastActiveTime).getTime();
        //         let remTime = Math.floor((dateNowTime - lastActiveTime) / 1000);
        //         this.setState({
        //             sessionRemainingTime: maxLogoutTime - remTime
        //         })
        //         if (remTime >= maxLogoutTime) {
        //             this.signOutHandler()
        //         }
        //     }, 1000)
        // }
    }

    menuhandler() {
        if (this.state.menustatesmall === false) {
            document.getElementById('dropdownsmall').style.display = 'block'
            this.setState({ menustatesmall: true })

        }
        if (this.state.menustatesmall === true) {
            document.getElementById('dropdownsmall').style.display = 'none'
            this.setState({ menustatesmall: false })
        }

    }
    mainMenuHandler(e,type) {
        console.log(type, 'type')
        e.stopPropagation()
        this.setState({ menuType: type })
        const { admin, } = this.props;  // state props
        if (admin !== undefined && admin.role === 'superuser') {
            switch (type) {
                case 'LOS':
                    this.setState({ pageViewState: 'leadMainPage' });
                    break;
                case 'LMS':
                    this.setState({ pageViewState: 'recoveryDisburse' });
                    break;
                case 'BUSINESS':
                    this.setState({ pageViewState: 'combineBranchBuddyLoanReport' });
                    break;
                case 'FINANCE':
                    this.setState({ pageViewState: 'assetQuality' });
                    break;
                default:
                    break;
            }
        }

    }
    render() {
        const { admin, crmNotification, selectedNavOptions, activityData, adminDataForLandingpage } = this.props;  // state props
        return (
            <div className="container-fluid full-height">
                <div className="row">
                    <div className="col-xs-12 no-padding">
                        <CRMHeader
                            pageViewState={this.state.pageViewState}
                            mainMenuHandler={this.mainMenuHandler.bind(this)}
                            menustatesmall={this.state.menustatesmall}
                            menuType={this.state.menuType}
                            menuhandler={this.menuhandler.bind(this)}
                            signOutHandler={this.signOutHandler.bind(this)}
                            headerNotificationHandler={this.headerNotificationHandler.bind(this)}
                            notificationCount={crmNotification != null && crmNotification != undefined ? crmNotification.count : ''}
                            notificationDocUpCount={crmNotification != null && crmNotification != undefined ? crmNotification.whatsAppCount : ''}
                            menuShowHandler={this.menuShowHandler.bind(this)}
                            menuState={this.state.menuState}
                            admin={admin}
                            sessionRemainingTime={this.state.sessionRemainingTime}
                            handleSongFinishedPlaying={this.handleSongFinishedPlaying.bind(this)}
                            notificationSoundState={this.state.notificationSoundState}
                            pageViewHandler={this.pageViewHandler.bind(this)}
                            playingState={this.state.playingState}
                            notificationType={this.state.notificationType}
                        />
                        {this.state.notificationState == true ?
                            <NotificationPage
                                notificationData={crmNotification != null && crmNotification != undefined ? crmNotification.list : ""}
                                deleteNotificationOne={this.deleteNotificationOne.bind(this)}
                                deleteNotificationAll={this.deleteNotificationAll.bind(this)}
                                notificationType={this.state.notificationType}
                            />
                            : ""}
                    </div>
                </div>

                {this.state.menuState == true ?
                    <SettingsOption
                        mainMenuHandler={this.mainMenuHandler.bind(this)}
                        menuType={this.state.menuType}
                        selectedIndex={selectedNavOptions}
                        changeContent={this.changeContentHandler.bind(this)}
                        admin={admin}
                        getPageState={this.getPageState.bind(this)}
                    />
                    : ""}
                <div className="row" style={{ height: "calc(100% - 72px)" }}>
                    {/* {admin.rolelist != undefined && admin.rolelist.indexOf(ROLE.LEAD_SELECTION) >= 0 ?
                        <button className="activity-btn" onClick={this.activityShowHandler.bind(this)}>
                            <span className="fa fa-font fa-lg" />
                        </button>
                        : null} */}
                    <div className="col-xs-12 no-padding full-height overflow-auto" id="profileContainer" onClick={(e) => this.closeMenu()}>
                        <ProfileContainer
                            mainMenuHandler={this.mainMenuHandler.bind(this)}
                            adminDataForLandingpage={adminDataForLandingpage}
                            pageViewHandler={this.pageViewHandler.bind(this)}
                            admin={admin}
                            signOutHandler={this.signOutHandler.bind(this)}
                            newDataBackground={this.state.newDataBackground}
                            pageViewState={this.state.pageViewState} />
                        {this.state.soundSettingPageState == true ?
                            <SoundSetting
                                soundHandler={this.soundHandler.bind(this)}
                                playingState={this.state.playingState}
                                closeSoundPopup={this.closeSoundPopup.bind(this)} />
                            : ""}
                    </div>
                    {this.state.activityViewState == true ?
                        <ActivityUi
                            admin={admin}
                            activityData={activityData}
                            activityLoaderState={this.state.activityLoaderState} />
                        : ""}
                </div>
            </div>
        );
    }

    activityShowHandler() {
        this.setState({ activityViewState: true })
    }

    getPageState(page) {
        this.setState({ pageViewState: page })
    }

    pageViewHandler(e, viewType) {
        e.stopPropagation();
        console.log(viewType, 'viewTypeviewType')
        if (viewType == 'SoundSetting') {
            this.setState({ soundSettingPageState: true })
        } else {
            
            this.setState({ pageViewState: viewType })
        }
    }
    closeSoundPopup() {
        this.setState({ soundSettingPageState: false })
    }

    soundHandler(e) {
        this.setState({ playingState: e.target.checked })
    }

    handleSongFinishedPlaying() {
        this.setState({ notificationSoundState: false })
    }

    headerNotificationHandler(type) {
        let newDataBackground = Object.assign({}, this.state.newDataBackground);
        newDataBackground.color = ""
        this.setState({
            notificationState: !this.state.notificationState,
            notificationSoundState: false,
            newDataBackground,
            notificationType: type
        })
        fetch(APIS.GET_READ_NOTIFICATION + type)
            .then(json => json.text())
            .then(res => {
                if (res == 'success') {
                    this.props.getAllNotification((callBack) => {

                    })
                }
            })
    }
    deleteNotificationOne(notId) {
        fetch(APIS.DELETE_CRM_NOTIFICATION_ONE + notId)
            .then(json => json.text())
            .then(res => {
                if (res == 'success') {
                    this.props.getAllNotification((callBack) => {

                    })
                }
            })
    }
    deleteNotificationAll() {
        fetch(APIS.DELETE_CRM_NOTIFICATION)
            .then(json => json.text())
            .then(res => {
                if (res == 'success') {
                    this.props.getAllNotification((callBack) => {

                    })
                }
            })
    }

    menuShowHandler(menuType) {
        if (menuType == 'hide') {
            setTimeout(() => {
                this.setState({
                    menuState: false,
                })
            }, 300);
            if (document.getElementById("CRMMenu") != null) {
                document.getElementById("CRMMenu").classList.remove("animate-crm-menu")
                document.getElementById("CRMMenu").classList.add("animate-crm-menu-back")
            }
        } else if (menuType == 'show') {
            this.setState({ menuState: true })
            if (document.getElementById("CRMMenu") != null) {
                document.getElementById("CRMMenu").classList.add("animate-crm-menu")
                document.getElementById("CRMMenu").classList.remove("animate-crm-menu-back")
            }
        }
    }

    closeMenu() {
        this.menuShowHandler('hide')
        this.setState({ notificationState: false, activityViewState: false })
    }

    signOutHandler() {
        this.props.logOutHandler();
    }

    changeContentHandler(e, index) {
        this.menuShowHandler('hide')
        this.props.onContentChange(index);
    }

    componentWillUnmount() {
        clearInterval(interval);
        clearInterval(activityInterval);
    }
}

const mapStateToProps = ({ CRMMenu, crmNotification, admin, activityData }) => {
    return {
        selectedNavOptions: CRMMenu.OPTIONS.selectedNavProp,
        crmNotification: crmNotification,
        admin: admin,
        activityData: activityData
    };
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        onChangeSideNav,
        onContentChange,
        resetSettingState,
        getAllNotification,
        addLoanApprovalLead,
        getLoanApprovalLead,
        getAllStatus,
        getActivityData
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(CRMMainPageContainer);