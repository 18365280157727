import React, { useState } from 'react';
import VerifyImage from '../../../../images/verified.png';
import NotVerified from '../../../../images/cross.png';
import moment from 'moment';
import { updatePaymentBreakupApi } from '../AdminActionCreator.component';

const EmiBreakUp = ({ close, payData, leadData, admin }) => {
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [selectedData, setSelectedData] = useState(null);

    const handleEditClick = (data) => {
        setSelectedData(data);
        setIsPopupOpen(true);
    };

    const handleInputChange = (field, value) => {
        setSelectedData((prev) => ({
            ...prev,
            [field]: value,
        }));
    };

    const handleSave = () => {
        if (selectedData) {
            const updatedData = {
                ...selectedData,
                verifiedBy: admin.emailId,
            };
            updatePaymentBreakupApi(updatedData, callback => {
                setIsPopupOpen(false);
                setSelectedData(null);
            })

            console.log('Saving data:', updatedData);



        }
    };

    const handleClosePopup = () => {
        setIsPopupOpen(false);
        setSelectedData(null);
    };

    return (
        <>
            <div className="background-blur " onClick={close}></div>
            <div
                className="popup-main small-popup container-fluid dbl-border"
                style={{ marginTop: '40px', width: '90%', maxWidth: '90%' }}
            >
                <div className="popup-content">
                    <h4 className="text-center">
                        Payment Breakdown for {leadData.applicationId}
                    </h4>
                    <div className="phocket-table-new" style={{ overflow: 'auto' }}>
                        <table>
                            <thead>
                                <tr>
                                    <th>Bounce GST</th>
                                    <th>Created At</th>
                                    <th>EMI Amount</th>
                                    <th>Interest Amount</th>
                                    <th>Is Active</th>
                                    <th>NACH Bounce</th>
                                    <th>NACH Bounce Waived</th>
                                    <th>Overdue Amount</th>
                                    <th>Penalty</th>
                                    <th>Penalty GST</th>
                                    <th>Penalty Waived</th>
                                    <th>Rate of Interest</th>
                                    <th>Edit</th>
                                    <th>Updated At</th>
                                </tr>
                            </thead>
                            <tbody>
                                {payData.map((data) => (
                                    <tr key={data.id}>
                                        <td style={{ textAlign: 'center' }}>{data.bounceGst}</td>
                                        <td style={{ textAlign: 'center' }}>
                                            {moment(data.createdAt).format('DD-MM-YYYY')}
                                        </td>
                                        <td style={{ textAlign: 'center' }}>{data.emiAmount}</td>
                                        <td style={{ textAlign: 'center' }}>{data.interestAmount}</td>
                                        <td style={{ textAlign: 'center' }}>
                                            {data.isActive ? (
                                                <img
                                                    src={VerifyImage}
                                                    width="22px"
                                                    alt="Verified"
                                                />
                                            ) : (
                                                <img
                                                    src={NotVerified}
                                                    width="20px"
                                                    alt="Not Verified"
                                                />
                                            )}
                                        </td>
                                        <td style={{ textAlign: 'center' }}>{data.nachBounce}</td>
                                        <td style={{ textAlign: 'center' }}>{data.nachBounceWaived}</td>
                                        <td style={{ textAlign: 'center' }}>{data.overdueAmount}</td>
                                        <td style={{ textAlign: 'center' }}>{data.penalty}</td>
                                        <td style={{ textAlign: 'center' }}>{data.penaltyGst}</td>
                                        <td style={{ textAlign: 'center' }}>{data.penaltyWaived}</td>
                                        <td style={{ textAlign: 'center' }}>{data.rateOfInterest}</td>
                                        <td style={{ textAlign: 'center' }}>
                                            <i
                                                className="fa fa-edit"
                                                onClick={() => handleEditClick(data)}
                                                style={{ cursor: 'pointer' }}
                                            ></i>
                                        </td>
                                        <td style={{ textAlign: 'center' }}>
                                            {moment(data.updatedAt).format('DD-MM-YYYY')}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                {isPopupOpen && selectedData && (
                    <div className="edit-popup">
                        <hr />
                        <div className="row">
                            <h4 style={{ marginLeft: '18px' }}>Edit Fields for selected Payment</h4>
                            <div className='col-sm-4 col-xs-12'>
                                <div className="form-group">
                                    <label>Penalty Waived</label>
                                    <input
                                        className='form-control'
                                        type="text"
                                        value={selectedData.penaltyWaived || ''}
                                        onChange={(e) =>
                                            handleInputChange('penaltyWaived', e.target.value)
                                        }
                                    />
                                </div>
                            </div>
                            <div className='col-sm-4 col-xs-12'>

                                <div className="form-group">
                                    <label>NACH Bounce Waived</label>
                                    <input
                                        type="text"
                                        className='form-control'
                                        value={selectedData.nachBounceWaived || ''}
                                        onChange={(e) =>
                                            handleInputChange('nachBounceWaived', e.target.value)
                                        }
                                    />
                                </div>
                            </div>
                            <div className="form-actions">
                                <button onClick={handleSave} className="btn btn-primary" style={{ marginTop: '22px' }}>
                                    Save
                                </button>
                                <button onClick={handleClosePopup} className="btn btn-secondary" style={{ marginTop: '22px' }}>
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>


        </>
    );
};

export default EmiBreakUp;
