import { APIS } from "../../../../../utils/api-factory"

export const getOperationEmployee = (page, size, callBack) => {
    fetch(APIS.GET_OPERATIONS_EMPLOYEE + page + '&size=' + size)
        .then(res => res.json())
        .then(json => {
            callBack(json)
        })
        .catch(err => {
            callBack(null)
        })
}
export const getPerformanceDataApi = (startDate, endDate, empId, callBack) => {
    fetch(APIS.GET_PERFORMANCE_DATA_BY_EMAIL + startDate + '&endDate=' + endDate + '&empId=' + empId)
        .then(res => res.json())
        .then(json => {
            callBack(json)
        })
        .catch(err => {
            callBack(null)
        })
}
export const getPerformanceDataGraphApi = (startDate, endDate, empId, callBack) => {
    fetch(APIS.GET_OPETRATION_PERFORMANCE_API + startDate + '&endDate=' + endDate + '&empId=' + empId)
        .then(res => res.json())
        .then(json => {
            callBack(json)
        })
        .catch(err => {
            callBack(null)
        })
}
export const getSoftApprovalCommentsApi = (startDate, endDate, empId, callBack) => {
    fetch(APIS.GET_SOFTAPPROVAL_API + startDate + '&endDate=' + endDate + '&empId=' + empId)
        .then(res => res.json())
        .then(json => {
            callBack(json)
        })
        .catch(err => {
            callBack(null)
        })
}
export const getProgressReportApi = (startDate, endDate, empId, callBack) => {
    fetch(APIS.GET_SMALL_DASHBOARD_OPERATION_API + startDate + '&endDate=' + endDate + '&empId=' + empId)
        .then(res => res.json())
        .then(json => {
            callBack(json)
        })
        .catch(err => {
            callBack(null)
        })
}
export const getOperationTabularDataApi = (startDate, endDate, empId, process, callBack) => {
    fetch(APIS.OPERTAIONS_TABULAR_DATA_API + startDate + '&endDate=' + endDate + '&empId=' + empId + '&process=' + process)
        .then(res => res.json())
        .then(json => {
            callBack(json)
        })
        .catch(err => {
            callBack(null)
        })
}

export const downloadOperationData = (startDate, endDate, empId, process, callBack) => {
    fetch(APIS.DOWNLOAD_OPERATION_REPORT_API + startDate + '&endDate=' + endDate + '&empId=' + empId + '&process=' + process)
        .then(res => res.blob())
        .then(data => {
            var blob = new Blob([data], { type: 'application/vnd.ms-excel' })
            var excelBlob = new Blob(
                [data],
                { type: 'application/vnd.ms-excel' },
                'excel.xls'
            )
            var link = window.URL.createObjectURL(excelBlob)
            window.location = link
            var downloadUrl = URL.createObjectURL(blob)
            var a = document.createElement('a')
            a.href = downloadUrl
            a.download = 'report.xls'
            document.body.appendChild(a)
            a.click()
            callBack(data)
        })
}




export const getCustomerServiceEmployee = (page, size, callBack) => {
    fetch(APIS.GET_CUSTOMER_SERVICE_EMPLOYEE + page + '&size=' + size)
        .then(res => res.json())
        .then(json => {
            callBack(json)
        })
        .catch(err => {
            callBack(null)
        })
}
export const getPerformanceDataApiCustomerService = (startDate, endDate, empId, callBack) => {
    fetch(APIS.GET_PERFORMANCE_DATA_BY_EMAIL_CUSTOMER_SERVICE + startDate + '&endDate=' + endDate + '&empId=' + empId)
        .then(res => res.json())
        .then(json => {
            callBack(json)
        })
        .catch(err => {
            callBack(null)
        })
}
export const getPerformanceDataGraphApiCustomerService = (startDate, endDate, empId, callBack) => {
    fetch(APIS.GET_CUSTOMER_SERVICE_PERFORMANCE_API + startDate + '&endDate=' + endDate + '&empId=' + empId)
        .then(res => res.json())
        .then(json => {
            callBack(json)
        })
        .catch(err => {
            callBack(null)
        })
}
export const getSoftApprovalCommentsApiCustomerService = (startDate, endDate, empId, callBack) => {
    fetch(APIS.GET_SOFTAPPROVAL_API_CUSTOMER_SERVICE + startDate + '&endDate=' + endDate + '&empId=' + empId)
        .then(res => res.json())
        .then(json => {
            callBack(json)
        })
        .catch(err => {
            callBack(null)
        })
}
export const getProgressReportApiCustomerService = (startDate, endDate, empId, callBack) => {
    fetch(APIS.GET_SMALL_DASHBOARD_OPERATION_API_CUSTOMER_SERVICE + startDate + '&endDate=' + endDate + '&empId=' + empId)
        .then(res => res.json())
        .then(json => {
            callBack(json)
        })
        .catch(err => {
            callBack(null)
        })
}
export const getOperationTabularDataApiCustomerService = (startDate, endDate, empId, process, callBack) => {
    fetch(APIS.OPERTAIONS_TABULAR_DATA_API_CUSTOMER_SERVICE + startDate + '&endDate=' + endDate + '&empId=' + empId + '&process=' + process)
        .then(res => res.json())
        .then(json => {
            callBack(json)
        })
        .catch(err => {
            callBack(null)
        })
}

export const downloadOperationDataCustomerService = (startDate, endDate, empId, process, callBack) => {
    fetch(APIS.DOWNLOAD_OPERATION_REPORT_API_CUSTOMER_SERVICE + startDate + '&endDate=' + endDate + '&empId=' + empId + '&process=' + process)
        .then(res => res.blob())
        .then(data => {
            var blob = new Blob([data], { type: 'application/vnd.ms-excel' })
            var excelBlob = new Blob(
                [data],
                { type: 'application/vnd.ms-excel' },
                'excel.xls'
            )
            var link = window.URL.createObjectURL(excelBlob)
            window.location = link
            var downloadUrl = URL.createObjectURL(blob)
            var a = document.createElement('a')
            a.href = downloadUrl
            a.download = 'report.xls'
            document.body.appendChild(a)
            a.click()
            callBack(data)
        })
}




export const getRepeatFreshEmployee = (page, size, callBack) => {
    fetch(APIS.GET_REPEAT_FRESH_EMPLOYEE + page + '&size=' + size)
        .then(res => res.json())
        .then(json => {
            callBack(json)
        })
        .catch(err => {
            callBack(null)
        })
}
export const getPerformanceDataApiRepeatFresh = (startDate, endDate, empId, callBack) => {
    fetch(APIS.GET_PERFORMANCE_DATA_BY_EMAIL_REPEAT_FRESH + startDate + '&endDate=' + endDate + '&empId=' + empId)
        .then(res => res.json())
        .then(json => {
            callBack(json)
        })
        .catch(err => {
            callBack(null)
        })
}
export const getPerformanceDataGraphApiRepeatFresh = (startDate, endDate, empId, callBack) => {
    fetch(APIS.GET_REPEAT_FRESH_PERFORMANCE_API + startDate + '&endDate=' + endDate + '&empId=' + empId)
        .then(res => res.json())
        .then(json => {
            callBack(json)
        })
        .catch(err => {
            callBack(null)
        })
}
export const getSoftApprovalCommentsApiRepeatFresh = (startDate, endDate, empId, callBack) => {
    fetch(APIS.GET_SOFTAPPROVAL_API_REPEAT_FRESH + startDate + '&endDate=' + endDate + '&empId=' + empId)
        .then(res => res.json())
        .then(json => {
            callBack(json)
        })
        .catch(err => {
            callBack(null)
        })
}
export const getProgressReportApiRepeatFresh = (startDate, endDate, empId, callBack) => {
    fetch(APIS.GET_SMALL_DASHBOARD_OPERATION_API_REPEAT_FRESH + startDate + '&endDate=' + endDate + '&empId=' + empId)
        .then(res => res.json())
        .then(json => {
            callBack(json)
        })
        .catch(err => {
            callBack(null)
        })
}
export const getOperationTabularDataApiRepeatFresh = (startDate, endDate, empId, process, callBack) => {
    fetch(APIS.OPERTAIONS_TABULAR_DATA_API_REPEAT_FRESH + startDate + '&endDate=' + endDate + '&empId=' + empId + '&process=' + process)
        .then(res => res.json())
        .then(json => {
            callBack(json)
        })
        .catch(err => {
            callBack(null)
        })
}

export const downloadOperationDataRepeatFresh = (startDate, endDate, empId, process, callBack) => {
    fetch(APIS.DOWNLOAD_OPERATION_REPORT_API_REPEAT_FRESH + startDate + '&endDate=' + endDate + '&empId=' + empId + '&process=' + process)
        .then(res => res.blob())
        .then(data => {
            var blob = new Blob([data], { type: 'application/vnd.ms-excel' })
            var excelBlob = new Blob(
                [data],
                { type: 'application/vnd.ms-excel' },
                'excel.xls'
            )
            var link = window.URL.createObjectURL(excelBlob)
            window.location = link
            var downloadUrl = URL.createObjectURL(blob)
            var a = document.createElement('a')
            a.href = downloadUrl
            a.download = 'report.xls'
            document.body.appendChild(a)
            a.click()
            callBack(data)
        })
}



export const getRepeatEmployee = (page, size, callBack) => {
    fetch(APIS.GET_REPEAT_EMPLOYEE + page + '&size=' + size)
        .then(res => res.json())
        .then(json => {
            callBack(json)
        })
        .catch(err => {
            callBack(null)
        })
}
export const getPerformanceDataApiRepeat = (startDate, endDate, empId, callBack) => {
    fetch(APIS.GET_PERFORMANCE_DATA_BY_EMAIL_REPEAT + startDate + '&endDate=' + endDate + '&empId=' + empId)
        .then(res => res.json())
        .then(json => {
            callBack(json)
        })
        .catch(err => {
            callBack(null)
        })
}
export const getPerformanceDataGraphApiRepeat = (startDate, endDate, empId, callBack) => {
    fetch(APIS.GET_REPEAT_PERFORMANCE_API + startDate + '&endDate=' + endDate + '&empId=' + empId)
        .then(res => res.json())
        .then(json => {
            callBack(json)
        })
        .catch(err => {
            callBack(null)
        })
}
export const getSoftApprovalCommentsApiRepeat = (startDate, endDate, empId, callBack) => {
    fetch(APIS.GET_SOFTAPPROVAL_API_REPEAT + startDate + '&endDate=' + endDate + '&empId=' + empId)
        .then(res => res.json())
        .then(json => {
            callBack(json)
        })
        .catch(err => {
            callBack(null)
        })
}
export const getProgressReportApiRepeat = (startDate, endDate, empId, callBack) => {
    fetch(APIS.GET_SMALL_DASHBOARD_OPERATION_API_REPEAT + startDate + '&endDate=' + endDate + '&empId=' + empId)
        .then(res => res.json())
        .then(json => {
            callBack(json)
        })
        .catch(err => {
            callBack(null)
        })
}
export const getOperationTabularDataApiRepeat = (startDate, endDate, empId, process, callBack) => {
    fetch(APIS.OPERTAIONS_TABULAR_DATA_API_REPEAT + startDate + '&endDate=' + endDate + '&empId=' + empId + '&process=' + process)
        .then(res => res.json())
        .then(json => {
            callBack(json)
        })
        .catch(err => {
            callBack(null)
        })
}

export const downloadOperationDataRepeat = (startDate, endDate, empId, process, callBack) => {
    fetch(APIS.DOWNLOAD_OPERATION_REPORT_API_REPEAT + startDate + '&endDate=' + endDate + '&empId=' + empId + '&process=' + process)
        .then(res => res.blob())
        .then(data => {
            var blob = new Blob([data], { type: 'application/vnd.ms-excel' })
            var excelBlob = new Blob(
                [data],
                { type: 'application/vnd.ms-excel' },
                'excel.xls'
            )
            var link = window.URL.createObjectURL(excelBlob)
            window.location = link
            var downloadUrl = URL.createObjectURL(blob)
            var a = document.createElement('a')
            a.href = downloadUrl
            a.download = 'report.xls'
            document.body.appendChild(a)
            a.click()
            callBack(data)
        })
}
export const getPerformanceDataAPiNew = (empId, startDate, endDate, callBack) => {
    fetch(APIS.DATE_WISE_GRAPH_DATA + 'shubham.goyal@credfin.money' + '&sDate=' + startDate + '&eDate=' + endDate )
        .then(res => res.json())
        .then(json => {
            callBack(json)
        })
        .catch(err => {
            callBack(null)
        })
}